@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
}

@layer components {
  .title {
    @apply bg-gradient-to-b from-white via-neutral-200 to-neutral-400 bg-clip-text font-extrabold  tracking-tighter text-4xl xl:text-6xl md:text-5xl text-transparent;
  }
  .socialIcons {
    @apply opacity-50 transition duration-300 ease-in-out hover:opacity-100;
  }

  .rainbowButton {
    min-height: 100%;
    background: linear-gradient(
        90deg,
        rgba(255, 0, 0, 1) 0%,
        rgba(255, 154, 0, 1) 10%,
        rgba(208, 222, 33, 1) 20%,
        rgba(79, 220, 74, 1) 30%,
        rgba(63, 218, 216, 1) 40%,
        rgba(47, 201, 226, 1) 50%,
        rgba(28, 127, 238, 1) 60%,
        rgba(95, 21, 242, 1) 70%,
        rgba(186, 12, 248, 1) 80%,
        rgba(251, 7, 217, 1) 90%,
        rgba(255, 0, 0, 1) 100%
      )
      0 0/800% 800%;
    animation: a 110s linear infinite;
  }

  @keyframes a {
    to {
      background-position: 800% 0%;
    }
  }
}
